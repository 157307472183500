import React, { useEffect, useRef, useState } from "react";
import { Button, Image, Media, Modal } from "react-bootstrap";
import NoDataFound from "../NoDataFound/NoDataFound";
import PostForwardUserCard from "./PostForwardUserCard";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { DISPATCH_ACTION } from "@paypal/react-paypal-js";
import { Link } from "react-router-dom";

import {
  fetchForwardPostFollowerUserListStart,
  fetchForwardPostFollowerUserListSuccess,
  fetchForwardPostUsersSearchListStart,
  fetchSearchPageContentStart,
  sentForwardPostStart,
} from "../../store/actions/PostAction";
import { Chip } from "@material-ui/core";
import "./postForward.css";
import useDebounce from "../Accounts/List/userDebounceHook";
import { searchListUserStart } from "../../store/actions/HomeAction";
import { t } from "react-multi-lang";
import VerifiedBadgeNoShadow from "../Handlers/VerifiedBadgeNoShadow";
import CommonCenterLoader from "../Loader/CommonCenterLoader";

const PostForwardModel = ({ isOpenModal, handleCloseModal, isPostId ,  selectedUsers, setSelectedUsers, setInputValue, inputValue, fromType}) => {
  const { data, loading, error } = useSelector(
    (state) => state.post.forwardFollowerUsersList
  );
  const {
    data: forwardSearchData,
    loading: forwardSearchLoading,
    error: forwardSearchError,
  } = useSelector((state) => state.post.forwardPostUsersSearchList);
  const {
    loading: submitSentForwardLoading,
  } = useSelector((state) => state.post.sentForwardPost);
  // const [selectedUsers, setSelectedUsers] = useState([]);
  // const [inputValue, setInputValue] = useState("");
  const [show, toggleShow] = useState(false);
  const debouncedQuery = useDebounce(inputValue, 300);
  const [isSelectUser, setIsSelectUser] = useState(false);
  const dropdownRef = useRef(null);

  console.log("🚀 ~ PostForwardModel ~ inputValue:", inputValue);
  const dispatch = useDispatch();

  const handleInputChnage = (e) => {
    setInputValue(e.target.value);
  };

  console.log("🚀 ~ PostForwardModel ~ forwardSearchData:", forwardSearchData);

  console.log("🚀 ~ PostForwardModel ~ selectedUsers:", selectedUsers);

  const handleUserCheck = (e, user) => {
    const isUserCheckedTrueOrFalseData = data.followers?.map((follower) => {
      if (follower?.follower_id === user?.follower_id) {
        return { ...follower, isActive: e.target.checked ? true : false };
      } else {
        return follower;
      }
    });

    dispatch(
      fetchForwardPostFollowerUserListSuccess({
        total: data?.total,
        followers: isUserCheckedTrueOrFalseData,
      })
    );

    const selectedUpdatedData = e.target.checked
      ? [...selectedUsers, { id: user.follower_id, username: user.username }]
      : selectedUsers.filter((ele) => ele.id !== user.follower_id);

    setSelectedUsers(selectedUpdatedData);

    /*     if (e.target.checked) {
 
      const updatedUsers = data.followers?.map((follower)=>{
         if(follower?.follower_id === user?.follower_id){
           return {...follower, isActive : true}
         }else{
          return follower;
         }
      })

      dispatch(fetchForwardPostFollowerUserListSuccess({total: data?.total , followers: updatedUsers} ));

      setSelectedUsers([
        ...selectedUsers,
        { id: user.follower_id, username: user.username },
      ]);
    } else {
      const selectedUser = selectedUsers.filter(
        (ele) => ele.id !== user.follower_id
      );
      setSelectedUsers(selectedUser);
    } */
  };

  const handleAddUserList = (user) => {
    console.log("🚀 ~ handleAddUserList ~ user:", user);
    const findUser = selectedUsers.find((userS) => userS?.id === user?.user_id);

    if (!findUser) {
      setSelectedUsers([
        ...selectedUsers,
        { id: user.user_id, username: user.username },
      ]);
      const updatedUsersList = data?.followers?.map((follower) => {
        if (follower?.follower_id === user.user_id) {
          return { ...follower, isActive: true };
        } else {
          return follower;
        }
      });

      dispatch(
        fetchForwardPostFollowerUserListSuccess({
          total: data?.total,
          followers: updatedUsersList,
        })
      );
    } else {
      // const updatedData = selectedUsers.filter((ele) => ele.id !== user.user_id)
      // setSelectedUsers(updatedData);
    }

    toggleShow(false);
    setInputValue("");
  };

  const handleRemoveUser = (userData) => {
    const filtredUser = selectedUsers.filter(
      (user) => user?.id !== userData?.id
    );
    setSelectedUsers(filtredUser);

    const filtreUsersIsActiveFalseData = data?.followers?.map((follower) => {
      if (userData?.id === follower?.follower_id) {
        return { ...follower, isActive: false };
      } else {
        return follower;
      }
    });

    dispatch(
      fetchForwardPostFollowerUserListSuccess({
        total: data?.total,
        followers: filtreUsersIsActiveFalseData,
      })
    );
  };

  const handleSubmitShare = (e) => {
    e.preventDefault();
    const updatedUsers = selectedUsers.map((user) => user.id);
    console.log("🚀 ~ handleSubmitShare ~ updatedUsers:", updatedUsers.toString(),isPostId);
    dispatch(
      sentForwardPostStart({
        data : {
          post_id: isPostId,
          user_id: updatedUsers.toString(),
        },

        handleCloseModal: handleCloseModal
      })
    );
    
      setSelectedUsers([]);
      setInputValue("");
  };

  useEffect(() => {
    dispatch(fetchForwardPostFollowerUserListStart());
  }, []);

  useEffect(() => {
    if (inputValue === "") {
      toggleShow(false);
    } else {
      toggleShow(true);

      if (debouncedQuery) {
        dispatch(
          fetchForwardPostUsersSearchListStart({
            search: debouncedQuery,
            type: "user",
          })
        );
      }
    }
  }, [debouncedQuery]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      toggleShow(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Modal
      show={isOpenModal}
      onHide={handleCloseModal}
      keyboard={false}
      centered
      className={`${
        localStorage.getItem("theme") !== "" &&
        localStorage.getItem("theme") !== null &&
        localStorage.getItem("theme") !== undefined &&
        localStorage.getItem("theme") === "dark"
          ? "dark-theme-modal custom-modal-container postForwardModel"
          : "custom-modal-container postForwardModel"
      }`}
      style={{ margin: "0 auto", width: "35%" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>share post</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="main-post-forward-container">
          <div style={{ display: "flex", flexDirection: "column", gap: "9px" }}>
            <div
              className=""
              style={{ display: "flex", flexDirection: "row", gap: "3px" }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "13px",
                  marginBottom: "0px",
                }}
              >
                To:
              </p>
              {selectedUsers.length > 0 && (
                <div className="main-post-forward-chips-wrapper">
                  {selectedUsers.map((user) => {
                    return (
                      <Chip
                        key={user?.id}
                        label={user?.username}
                        variant="outlined"
                        onDelete={() => handleRemoveUser(user)}
                      />
                    );
                  })}
                </div>
              )}
            </div>
            <div className="post-forward-search-user-input-wrapper">
              <input
                type="text"
                className="post-forward-input"
                placeholder="Search..."
                name="forward_user_search"
                onChange={handleInputChnage}
                value={inputValue}
              />
              {show && (
                <div
                  className="search-dropdown-post-forward-sec search-dro-down-list-post-forward-users"
                  style={{ position: "absolute", cursor: "pointer" }}
                  ref={dropdownRef}
                >
                  <ul className="list-unstyled search-dropdown-list-sec">
                    {forwardSearchLoading ? (
                      <CommonCenterLoader />
                    ) : forwardSearchData?.length > 0 ? (
                      forwardSearchData?.map((user) => (
                        <Media as="li" key={user.user_unique_id}>
                          {/*     <Link to={"#"} onClick={()=> {
                      
                      //  handleSingleListUser(user?.user_id, user);
                       }}> */}
                          <div onClick={() => handleAddUserList(user)}>
                            <div className="search-body">
                              <div className="user-img-sec">
                                <Image
                                  alt="#"
                                  src={user.picture}
                                  className="user-img"
                                />
                              </div>
                              <div className="search-content">
                                <h5>
                                  {user.name}{" "}
                                  {user.is_verified_badge == 1 ? (
                                    <div className="pl-2">
                                      <VerifiedBadgeNoShadow />
                                    </div>
                                  ) : null}
                                </h5>
                                <p className="text-muted f-12">
                                  @{user.username}
                                </p>
                              </div>
                            </div>
                            {/* </Link> */}
                          </div>
                        </Media>
                      ))
                    ) : (
                      t("no_user_found")
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div
            className="main-post-forward-users-container"
            style={{ display: "flex", flexDirection: "column", gap: "4px" }}
          >
            <h5 style={{ fontWeight: "600" }}>Suggested</h5>
            <div className="main-post-forward-users-wrapper">
              {loading ? (
                [...Array(4)].map(() => (
                  <div
                    className="post_forward_user_card_details"
                    style={{ width: "100%", marginTop: "7px" }}
                  >
                    <Skeleton
                      className="profile-forward-post-card-img-loader"
                      style={{
                        height: "47px",
                        width: "47px",
                        borderRadius: "50%",
                        objectFit: "fill",
                      }}
                    />
                    <div
                      className="post_forward_user_card_user_info"
                      style={{ width: "100%" }}
                    >
                      <Skeleton
                        className="profile-forward-post-card-p-loader"
                        style={{ height: "47px" }}
                      />
                    </div>
                  </div>
                ))
              ) : data.followers?.length > 0 ? (
                data.followers?.map((user) => {
                  return (
                    <PostForwardUserCard
                      key={user?.follower_id}
                      user={user}
                      handleUserCheck={handleUserCheck}
                    />
                  );
                })
              ) : (
                <NoDataFound from={"post_forward"} />
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant=""
          size="lg"
          className="ftr-btn forward-post-ftr-btn"
          style={{ width: "100%" }}
          disabled={selectedUsers.length <= 0 || submitSentForwardLoading}
          onClick={handleSubmitShare}
        >
            {submitSentForwardLoading ? "Loading..." : "Share" }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PostForwardModel;
