import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { translate } from "react-multi-lang";
import SubscribingCard from "../SubscribingCard";
import NoDataFound from "../../NoDataFound/NoDataFound";
import FollowingLoader from "../../Loader/FollowingLoader";
import { fetchListsDetailsStart } from "../../../store/actions/HomeAction";
import { useDispatch } from "react-redux";

const SubscribingActiveSec = (props) => {
  const dispatch = useDispatch();
  const [sortedUserData, setSortedUserData] = useState([]);

  // Fetch list details on mount
  useEffect(() => {
    dispatch(fetchListsDetailsStart());
  }, []);

  // Sorting logic based on props.sortingToogle
  useEffect(() => {
    if (props.userData && props.userData.length > 0) {
      const sortedData = [...props.userData];
      if (props.sortingToogle.aToz) {
        // Sort A to Z by username
        sortedData.sort((a, b) => a.to_user?.username.localeCompare(b.to_user?.username));
      } else if (!props.sortingToogle.aToz) {
        // Sort Z to A by username
        sortedData.sort((a, b) => b.to_user?.username.localeCompare(a.to_user?.username));
      }
      if (props.sortingToogle.upToDown) {
        // Sort latest to oldest by updated_at
        sortedData.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      }
      setSortedUserData(sortedData);
    } else {
      setSortedUserData([]);
    }
  }, [props.userData, props.sortingToogle]);

  return (
    <>
      <div
        role="tabpanel"
        className={
          props.activeSec === "active-sec"
            ? "tab-pane fade in active"
            : "tab-pane fade"
        }
        id="active"
      >
        {props.subscribing.loading ? (
          <FollowingLoader />
        ) : (
          <>
            <Row>
              {sortedUserData && sortedUserData.length > 0 ? (
                sortedUserData.map((item) => (
                  <SubscribingCard
                    key={item.id}
                    pagePath={props.pagePath}
                    userData={item}
                    user={item.to_user}
                    isFavUser={item?.is_fav_user}
                    isFriend={item?.is_friend}
                    subscribingType={props.pagePath === "subscribing"}
                    setUserData={props?.setUserData}
                    setCheckedUserId={props?.setCheckedUserId}
                    checkedUserId={props?.checkedUserId}
                    isSelected={props?.isSelected}
                    isSelectedData={props?.userData}
                  />
                ))
              ) : (
                <NoDataFound />
              )}
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default translate(SubscribingActiveSec);
