import React, { useEffect, useState } from "react";
import { translate, t } from "react-multi-lang";
import NoDataFound from "../../NoDataFound/NoDataFound";
import FollowingLoader from "../../Loader/FollowingLoader";
import { Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { fetchListsDetailsStart } from "../../../store/actions/HomeAction";
import FavouriteCard from "./FavouriteCard";

const FavoritesActiveSec = (props) => {
  const dispatch = useDispatch();
  const [sortedUserData, setSortedUserData] = useState([]);

  // Fetch list details on mount
  useEffect(() => {
    dispatch(fetchListsDetailsStart());
  }, []);

  // Sorting logic based on props.sortingToogle
  useEffect(() => {
    if (props.userData && props.userData.length > 0) {
      const sortedData = [...props.userData];
      if (props.sortingToogle?.aToz) {
        // Sort A to Z by username
        sortedData.sort((a, b) =>
          a.fav_user?.username?.localeCompare(b.fav_user?.username)
        );
      } else if (!props.sortingToogle?.aToz) {
        // Sort Z to A by username
        sortedData.sort((a, b) =>
          b.fav_user?.username?.localeCompare(a.fav_user?.username)
        );
      }
      if (props.sortingToogle?.upToDown) {
        // Sort latest to oldest by updated_at
        sortedData.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      }
      setSortedUserData(sortedData);
    } else {
      setSortedUserData([]);
    }
  }, [props.userData, props.sortingToogle]);

  return (
    <>
      <div
        role="tabpanel"
        className={
          props.activeSec === "active-sec"
            ? "tab-pane fade in active"
            : "tab-pane fade"
        }
        id="active"
      >
        {props.favorites.loading ? (
          <FollowingLoader />
        ) : (
          <>
            <Row>
              {sortedUserData && sortedUserData.length > 0 ? (
                sortedUserData.map((fav_user) =>
                  fav_user.fav_user ? (
                    <FavouriteCard
                      key={fav_user?.fav_user?.user_id}
                      fav_user={fav_user}
                      activeSec={props.activeSec}
                      setActiveSec={props.setActiveSec}
                      favorites={props.favorites}
                      userData={props.userData}
                      pagePath={props.pagePath}
                      isSelected={props.isSelected}
                      setUserData={props.setUserData}
                      setCheckedUserId={props.setCheckedUserId}
                      checkedUserId={props.checkedUserId}
                    />
                  ) : (
                    ""
                  )
                )
              ) : (
                <NoDataFound />
              )}
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default translate(FavoritesActiveSec);
