import React from "react";
import { Form, Button, Image, Modal, Tab, Row, Col, Nav, FormCheck, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import { Field, Formik } from "formik";
import './NewPpvChatRequest.css';
import * as Yup from 'yup';

const ppvRequestValidation = Yup.object().shape({
  ppvChatRequest: Yup.string()
                  .min(2,"Too short")
                  .required("ppv content request is required"),
  ppv_amount: Yup.number("Must be number")
                  .required("Budget is required")
                  .positive()
                  .integer()
                  .min(1, "Minimum 1 is requied")
})

const NewppvChatRequest = (props) => {
	const initialData = {
    ppv_amount: 1,
    ppvChatRequest: ""
  };

  return (
    <>
      <Modal
        className="modal-dialog-center chat-upload-modal"
        size="md"
        centered
        show={props.newPpvModel}
        onHide={props.closePpvRequestModal}
      >
        <Button className="modal-close" onClick={() => props.closePpvRequestModal()}>
          <Image
            className="close-icon"
            src={
              window.location.origin + "/assets/images/new-chat/modal-close.svg"
            }
          /> 
        </Button>
        <Modal.Body>
          <>
          
          <Formik
            initialValues={initialData}
            validationSchema={ppvRequestValidation}
            onSubmit={(values) => {
              console.log(values, "Logging the values of ppv chat request")
              
              if (props.message !== "") {
                props.handleMessageSubmit({
                  ppv_amount: Number(values.ppv_amount)
                })

                props.closePpvRequestModal()
              }
            }}  
          >
            {({errors, touched, setFieldValue, handleSubmit}) => (
              <form 
                className="ppv-content-request-container"
                onSubmit={handleSubmit} 
              >
                 <div>  
                  <Field
                    name="ppvChatRequest"
                    as="textarea"
                    onChange={(e) => {
                      setFieldValue('ppvChatRequest', e.target.value)
                      props.setMessage(e.target.value)  
                    }}
                    placeholder="Type what you want to request"
                    className="ppv-request-input"
                  />
                  {errors?.ppvChatRequest && touched?.ppvChatRequest 
                    ? 
                    (
                      <p className="ppv-request-erorr">
                        {errors?.ppvChatRequest}
                      </p>
                    )
                    : null
                  }
                </div>
                <div className="budget-input-ppv">
                  <label 
                    style={{
                      marginBottom: "0",
                      fontSize: "12px"
                    }}
                  >
                    Budget
                  </label>
                  <Field
                    name="ppv_amount"
                    type="number"
                    min="1"
                    className="budget-input-range"
                  />
                  {errors?.ppv_amount && touched?.ppv_amount 
                    ? 
                    (
                      <p className="ppv-request-erorr">
                        {errors?.ppv_amount}
                      </p>
                    )
                    : null
                  }
                </div>
                <button
                  type="submit"
                  className="send-request-btn"
                >
                  Send Request
                </button>
              </form>
            )}
          </Formik>
          </>
        </Modal.Body>

      </Modal>
    </>
  );
}

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  chatFilesUpload: state.chat.chatFilesUpload,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(NewppvChatRequest));

// export default NewppvChatRequest