import React, { useEffect } from "react";
import { connect } from "react-redux";
import { t, translate } from "react-multi-lang";
import FollowingLoader from "../../Loader/FollowingLoader";
import { Row } from "react-bootstrap";
import SingleListUserCard from "./SingleListUserCard";
import NoDataFound from "../../NoDataFound/NoDataFound";
import { searchListUserStart } from "../../../store/actions/HomeAction";

const SearchUserData = (props) => {

	const handleModifiyFilter = props.SelectedUserCate.map((ele)=> {
    return ele.payload;
  });

	const {sortingToogle, sortUser, selectedColorTags, searchUserVal} = props

  useEffect(() => {
        props.dispatch(
          searchListUserStart({
            search: "",
            filter: handleModifiyFilter.toString(),
            sort: props.sortUser,
            tag_title: props.selectedColorTags?.name || "",
            tag_colour: props.selectedColorTags?.color_tag_code || "",
            category:
              props.sortUser !== ""
                ? props.sortUser === "username"
                  ? props.sortingToogle.aToz === true
                    ? "ASC"
                    : "DESC"
                  : props.sortingToogle.upToDown === true
                  ? "ASC"
                  : "DESC"
                : "",
          })
        );

  }, [props.SelectedUserCate, sortingToogle, sortUser, selectedColorTags, searchUserVal]);


  return (
   
		<>
      {props.searchUser.loading ? (
        <FollowingLoader />
      ) : (
        <>
          <Row>
            {props.searchUser.data?.length > 0 ? (
              props.searchUser.data?.map((ele) => {
                return <SingleListUserCard user={ele} key={ele.user_id} />;
              })
            ) : (
              <NoDataFound />
            )}
          </Row>
        </>
      )}
		</>
  );
};

const mapStateToPros = (state) => ({
  searchUser: state.customList.userSearchList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(SearchUserData));
