import React, { useState } from "react";
import { connect } from "react-redux";
import CommonCenterLoader from "../../Loader/CommonCenterLoader";
import { Image, InputGroup, FormControl, Media } from "react-bootstrap";
import {
  searchListUserStart,
} from "../../../store/actions/HomeAction";
import { Link } from "react-router-dom";
import VerifiedBadgeNoShadow from "../../Handlers/VerifiedBadgeNoShadow";
import { translate, t } from "react-multi-lang";
import { useEffect } from "react";
import useDebounce from "./userDebounceHook";
import { useHistory } from "react-router-dom";






const UserSearchListIndex = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const debouncedQuery = useDebounce(props.searchUserVal, 300);
  const [isSelectUser, setIsSelectUser] = useState(false);

  const [show, toggleShow] = useState(false);

  const history = useHistory();

  const handleModifiyFilter = props.SelectedUserCate.map((ele)=> {
    return ele.payload;
  });

  const handleSearch = (event) => {

    props.setSearchUserVal(event.target.value);
    setIsSelectUser(true);

  };

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };


  const handleSingleListUser = (data, user)=> {
    setIsSelectUser(false);
    toggleShow(false);
  
    history.push({
      pathname: `/search-list/user/${data}`,
      state: { userName: user?.username }
    });
    localStorage.setItem("list-username", user?.username);
    props.setSearchUserVal(user.username);

  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);


  useEffect(() => {

    if (props.searchUserVal === "") {
      toggleShow(false);
    } else {
        
        toggleShow(true);
    
      if (debouncedQuery) {
        props.dispatch(
          searchListUserStart({
            search: debouncedQuery,
            filter: handleModifiyFilter.toString(),
            sort: props.sortUser,
            tag_title: props.selectedColorTags?.name || "",
            tag_colour: props.selectedColorTags?.color_tag_code || "",
            category:
              props.sortUser !== ""
                ? props.sortUser === "username"
                  ? props.sortingToogle.aToz === true
                    ? "ASC"
                    : "DESC"
                  : props.sortingToogle.upToDown === true
                  ? "ASC"
                  : "DESC"
                : "",
          })
        );
        
      }

     
    
    }
   
}, [debouncedQuery]);

  const renderSearch = () => {
    return (
      <div className="new-feed-search-sec" style={{position: "relative"}}>
        <InputGroup className="mb-0">
          <FormControl
            placeholder={t("search")}
            aria-describedby="basic-addon2"
            onChange={handleSearch}
            value={props.searchUserVal}
          />
          <InputGroup.Text id="basic-addon2">
            <Image
              className="new-feeds-search-icon"
              src={
                window.location.origin +
                "/assets/images/feed-story/search-icon.svg"
              }
            />
          </InputGroup.Text>
        </InputGroup>
        {/* {show &&  isSelectUser && (
          <div className="search-dropdown-sec search-dro-down-list-users"  style={{position: "absolute"}}>
            <ul className="list-unstyled search-dropdown-list-sec">
              {props.searchUser.loading ? (
                <CommonCenterLoader />
              ) : props.searchUser.data?.length > 0 ? (
                props.searchUser?.data?.map((user) => (
                  <Media as="li" key={user.user_unique_id}>
                    <Link to={"#"} onClick={()=> {
                      
                       handleSingleListUser(user?.user_id, user);
                       }}>
                      <div className="search-body">
                        <div className="user-img-sec">
                          <Image
                            alt="#"
                            src={user.picture}
                            className="user-img"
                          />
                        </div>
                        <div className="search-content">
                          <h5>
                            {user.name}{" "}
                            {user.is_verified_badge == 1 ? (
                              <div className="pl-2">
                                <VerifiedBadgeNoShadow />
                              </div>
                            ) : null}
                          </h5>
                          <p className="text-muted f-12">@{user.username}</p>
                        </div>
                      </div>
                     </Link>
                  </Media>
                ))
              ) : (
                t("no_user_found")
              )}
            </ul>
          </div>
        )} */}
      </div>
    );
  };

  return renderSearch();
};

const mapStateToPros = (state) => ({
  searchUser: state.customList.userSearchList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(UserSearchListIndex));
