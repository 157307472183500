import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  ADD_KYC_DOCUMENT_START,
  Add_PRIMARIY_INFO_SETP_8_START,
  GET_KYC_DOCUMENT_START,
  SENT_OTP_START,
  VERIFY_OTP_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  addKycDocumentSuccess,
  addKycDocumentFailure,
  getKycDocumentSuccess,
  getKycDocumentFailure,
  setOtpSuccess,
  setOtpStart,
  setOtpFailure,
  verifyOtpSuccess,
  verifyOtpFailure,
  addPrimaryInfoSuccess,
  addPrimaryInfoFailure,
} from "../actions/KycDocumentAction";

import {
  checkLogoutStatus,
} from "../actions/ErrorAction";
import { fetchUserDetailsSuccess } from "../actions/UserAction";

function* addKycDocumentAPI({data}) {
  try {
    // const inputData = yield select(
    //   (state) => state.kycDocument.addKycDocInput.inputData
    // );
    if (data) {
      const response = yield api.postMethod("documents_save", data, "forUploadDoc");
      console.log("🚀 ~ function*addKycDocumentAPI ~ response:", response)
      yield put(addKycDocumentSuccess(response.data.data));
      if (response.data.success) {
        const notificationMessage = getSuccessNotificationMessage(
          response.data.message
        );
        yield put(createNotification(notificationMessage));
      } else {
        yield put(addKycDocumentFailure(response.data.error));
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(checkLogoutStatus(response.data));
        yield put(createNotification(notificationMessage));
      }
    } else {
      let errorMessage = "Please upload the file and choose document";
      yield put(addKycDocumentFailure(errorMessage));
      const notificationMessage = getErrorNotificationMessage(errorMessage);
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(addKycDocumentFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getKycDocumentAPI() {
  try {
    const response = yield api.postMethod("documents_list");
    yield put(getKycDocumentSuccess(response.data.data));
    if (response.data.success) {
    } else {
      yield put(getKycDocumentFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(getKycDocumentFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* sentOtpApi({data}) {
  try {
    const response = yield api.postMethod("send_otp_to_user", { mobile_no : data.mobile_no});
    if (response.data.success) {
      data.setShowTimer(true);
      yield put(setOtpSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(setOtpFailure(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(setOtpFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* verifyOtpApi({data}) {
  try {
    const response = yield api.postMethod("verify_user_mobile_no", { otp : data.otp});
    if (response.data.success) {
      yield put(verifyOtpSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(verifyOtpFailure(response.data));
      
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(verifyOtpFailure(error));

    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* addPrimaryInfoApi({data}) {
  try {
    const response = yield api.postMethod("update_primary_profile", data.isFromCreator ? data.createrInfo : data.info);
    if (response.data.success) {

      yield put(addPrimaryInfoSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message 
      );

      yield put(createNotification(notificationMessage));

      if(data.isFromCreator && data?.isRedirectToCertified && data?.navigate && data?.handleClose){
          data.handleClose();
          data.navigate.push("/become-a-certified-creator");
          // setTimeout(()=>{
          //   window.location.reload();
          // },400)
         return;
      }
 
      if(data.isFromCreator){
        
        localStorage.setItem(
          "is_content_creator",
          response.data.data.is_content_creator
        );
        
        const userDetails = yield select(state => state.users.profile.data)

        const updatedUsersDetails = {
          ...userDetails,
          is_content_creator: response.data.data.is_content_creator
        }
        yield put(fetchUserDetailsSuccess({isFromAddPrimaryInfo: true, data: updatedUsersDetails}))
        return 
      };
      data.jumpToStep(1);
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(addPrimaryInfoFailure(response.data));
      
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(addPrimaryInfoFailure(error));

    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(ADD_KYC_DOCUMENT_START, addKycDocumentAPI)]);
  yield all([yield takeLatest(GET_KYC_DOCUMENT_START, getKycDocumentAPI)]);
  yield all([yield takeLatest(SENT_OTP_START, sentOtpApi)]);
  yield all([yield takeLatest(VERIFY_OTP_START, verifyOtpApi)]);
  yield all([yield takeLatest(Add_PRIMARIY_INFO_SETP_8_START, addPrimaryInfoApi)]);
}
