import React, { useState } from "react";
import { referralValidationStart } from "../../store/actions/UserAction";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { Container, Row, Col, Image, Button, Media, Form, InputGroup, FormControl } from "react-bootstrap";
import configuration from "react-global-configuration";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";


const StepRefferals = (props) => {

	const [referralCode, setReferralCode] = useState("");

	const checkReferralCode = (event) => {
    event.preventDefault();

    if (referralCode) {
      props.dispatch(referralValidationStart({ referral_code: referralCode }));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        t("please_enter_the_referral_code")
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  return (
    <div className="stepRefferal-content-sec">
			<div 
				style={{
					display: "flex",
					justifyContent: "center"
				}}
			>
      <Col md={6}>
        <Form.Group
          controlId="formHorizontalFirstname"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Form.Label style={{fontSize: "13px"}}>{t("refferal_st")}</Form.Label>

          {configuration.get("configData.is_referral_enabled") == 1 ? (
            <>
              <div class="form-group">
                <input
                  type="text"
                  name="referral_code"
                  placeholder={t("referral_code_optional")}
                  value={referralCode}
                  className="form-control mb-3"
                  onChange={(e) => setReferralCode(e.target.value)}
                />
                {/* <ErrorMessage
										component={"div"}
										name="referral_code"
										className="text-danger text-right"
									/> */}
                <div className="check-referral-link">
                  <a
                    className="text-primary"
                    href="#"
                    onClick={checkReferralCode}
                  >
                    {t("check_referral_code_valid")}
                  </a>
                </div>
              </div>
            </>
          ) : null}
        </Form.Group>
      </Col>
			</div>
			<div className="edit-save" style={{paddingBottom: 0}}>
					<Button
						className="btn gradient-btn gradientcolor addBank"
            onClick={() => {

              props.jumpToStep(4)
            }}
						// disabled={props.addBankAccount.buttonDisable}
					>
						Next
					</Button>
			</div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  null,
  mapDispatchToProps
)(translate(StepRefferals));

