import React, { useState } from "react";
import {
  Dropdown,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  Media,
  Modal,
} from "react-bootstrap";
import "./NewHome.css";
import { Link } from "react-router-dom";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import ReactPlayer from "react-player";
import SendTipModal from "../helper/SendTipModal";
import { savePostLikeStart } from "../../store/actions/PostLikesAction";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import SendTipPaymentModal from "../Model/PaymentModal/SendTipPaymentModal";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import CopyToClipboard from "react-copy-to-clipboard";
import ReportModeModal from "../helper/ReportModeModal";
import { saveBlockUserStart } from "../../store/actions/UserAction";
import { saveBookmarkStart } from "../../store/actions/BookmarkAction";
import VideoComponent from "./VideoComponent";
import "./NewFeedDisplayCard.css";
import { subscriptionPaymentStripeStart } from "../../store/actions/SubscriptionAction";
import {
  unFollowUserStart,
  unFollowUserSubStart,
} from "../../store/actions/FollowAction";
import SubscriptionPaymentModal from "../Model/PaymentModal/SubscriptionPaymentModal";
import { useHistory } from "react-router";
import PPVPaymentModal from "../Model/PaymentModal/PPVPaymentModal";

const NewFeedDisplayCard = (props) => {
  const { post, isActiveTab } = props;
  const [sendTip, setSendTip] = useState(false);
  const [reportMode, setReportMode] = useState(false);
  const [isShowDropDown, setIsShowDropDown] = useState(false);
  const [showUnfollow, setShowUnfollow] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({
    is_free: 0,
    plan_type: "months",
    amount: 0,
    amount_formatted: 0,
  });
  const [subscrptionPayment, setsubscrptionModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const history = useHistory();
  const closeSendTipModal = () => {
    setSendTip(false);
  };

  const closeReportModeModal = () => {
    setReportMode(false);
  };

  const handleLike = () => {
    props.dispatch(savePostLikeStart({ post_id: post.post_id }));
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      t("profile_link_copied")
    );
    props.dispatch(createNotification(notificationMessage));
    setIsShowDropDown(false);
  };

  const handleBlockUser = (event, post) => {
    event.preventDefault();
    // setPostDisplayStatus(false);
    props.dispatch(saveBlockUserStart({ user_id: post.user_id }));
  };

  const handleBookmark = () => {
    props.dispatch(saveBookmarkStart({ post_id: post.post_id }));
  };

  const handleUnfollow = (event, user_id) => {
    event.preventDefault();
    props.dispatch(
      unFollowUserStart({
        user_id: user_id,
      })
    );
  };

  const subscriptionPayment = (
    event,
    plan_type,
    amount,
    amount_formatted,
    is_free = 0
  ) => {
    event.preventDefault();
    if (localStorage.getItem("userId")) {
      setSubscriptionData({
        ...subscriptionData,
        is_free: is_free,
        plan_type: plan_type,
        amount: amount,
        amount_formatted: amount_formatted,
      });
      setsubscrptionModal(true);
    } else {
      const notificationMessage = getErrorNotificationMessage(
        t("login_to_continue")
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  const handleUnfollowModalClose = () => setShowUnfollow(false);
  const handleUnfollowModalShow = () => setShowUnfollow(true);

  const handleUnSubscriber = (event, user_id) => {
    event.preventDefault();
    props.dispatch(
      unFollowUserSubStart({
        user_id: user_id,
      })
    );
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
  };

  const closePostPaymentModal = () => {
    setsubscrptionModal(false);
  }
  const AutoplaySlider = withAutoplay(AwesomeSlider);

  const shareUrl = {
    facebook: "https://www.facebook.com/sharer/sharer.php?u",
    twitter: "https://twitter.com/intent/tweet?url",
    whatsapp: "https://api.whatsapp.com/send?text",
    messanger: "https://www.messenger.com/t/?link",
    pintrest: "https://www.pinterest.com/pin/create/button/?url",
    reddit: "https://www.reddit.com/submit?url",
    vk: "https://vk.com/share.php?url",
    linkedin: "https://www.linkedin.com/sharing/share-offsite/?url",
  };

  const redirectToProfile = () => {
    history.push(`/${post.user.unique_id}`);
  }

  return (
    <>
      <div className="new-feed-display-card">
        <div className="new-feed-header-sec">
          <div className="new-feed-user-info">
            <div className="live-streaming-user-img-sec">
              <Image className="new-feed-user-img" src={post.user_picture} />
            </div>
            <div className="new-feed-user-details">
              <h4>
                {/* {post.user_displayname} */}
                {post.is_verified_badge === 1 ? (
                  <span>
                    <Image
                      className="sidebar-verified-icon"
                      src={
                        window.location.origin +
                        "/assets/images/new-home/verified-icon.svg"
                      }
                    />
                  </span>
                ) : null}
              </h4>
              <Link
                to={`/${post.user_unique_id}`}
                style={{ display: "flex", gap: "7px" }}
              >
                {post.username}
                {post.user.is_certified_content_creator == 2 ? (
                  <span>
                    <Image
                      style={{ marginBottom: "2px" }}
                      className="sidebar-verified-icon"
                      src={
                        window.location.origin +
                        "/assets/images/new-home/verified-icon.svg"
                      }
                    />
                  </span>
                ) : null}
              </Link>
              {/* <Link to={`/${post.user_unique_id}`}>@{post.username}</Link> */}
            </div>
          </div>
          <div className="new-feed-user-btn-sec">
            {post?.user?.is_certified_content_creator == 2 && (
              <Button className="sent-tip-btn" onClick={() => setSendTip(true)}>
                <Image
                  className="sent-tip-icon"
                  src={
                    window.location.origin +
                    "/assets/images/feed-story/sent-tip-1.svg"
                  }
                />
                <span>{t("tips")}</span>
              </Button>
            )}

            <Dropdown
              className="feed-post-dropdown"
              show={isShowDropDown}
              onToggle={(isOpen) => setIsShowDropDown(isOpen)}
            >
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="feed-post-dropdown-btn"
              >
                <Image
                  className="three-dots-icon"
                  src={
                    window.location.origin +
                    "/assets/images/feed-story/3-vertical-dots.svg"
                  }
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <CopyToClipboard text={post.share_link} onCopy={onCopy}>
                  <Media as="li">
                    <Link to="#" className="dropdown-a">
                      {" "}
                      {t("copy_link_to_post")}{" "}
                    </Link>
                  </Media>
                </CopyToClipboard>
                <Media as="li" className="divider"></Media>
                <Media as="li">
                  <Link
                    to="#"
                    // onClick={(event) => handleReportPost(event, post)}
                    onClick={() => setReportMode(true)}
                    className="dropdown-a"
                  >
                    {t("report")}
                  </Link>
                </Media>
                {isActiveTab === "following" ? (
                  ""
                ) : (
                  <>
                    {post.show_follow === 1 && (
                      <Media as="li">
                        <Link
                          to="#"
                          // onClick={(event) => handleReportPost(event, post)}
                          onClick={() => {
                            if (localStorage.getItem("userId")) {
                              props.dispatch(
                                subscriptionPaymentStripeStart({
                                  user_unique_id: post?.user.user_unique_id,
                                  plan_type: "months",
                                  is_free: 0,
                                  pageType: "userCard",
                                })
                              );
                            } else {
                              const notificationMessage =
                                getErrorNotificationMessage(
                                  t("login_to_continue")
                                );
                              props.dispatch(
                                createNotification(notificationMessage)
                              );
                            }
                          }}
                          className="dropdown-a"
                        >
                          {t("subscribe_for_free")}
                        </Link>
                      </Media>
                    )}
                  </>
                )}
                {isActiveTab === "following" ? (
                  ""
                ) : (
                  <>
                    {post.show_unfollow === 1 && (
                      <Media as="li">
                        <Link
                          to="#"
                          // onClick={(event) => handleReportPost(event, post)}
                          onClick={(event) =>
                            handleUnfollow(event, post?.user?.user_id)
                          }
                          className="dropdown-a"
                          style={{
                            color: "#ff6060",
                          }}
                        >
                          {t("following")}
                        </Link>
                      </Media>
                    )}
                  </>
                )}
                {isActiveTab === "subscribing" ? (
                  ""
                ) : (
                  <>
                    {post?.payment_info?.unsubscribe_btn_status === 0 &&
                    post?.payment_info?.subscription_info?.monthly_amount ? (
                      <Media as="li">
                        <Link
                          to="#"
                          // onClick={(event) => handleReportPost(event, post)}
                          onClick={(event) => {
                            subscriptionPayment(
                              event,
                              "months",
                              post?.payment_info?.subscription_info
                                ?.monthly_amount,
                              post?.payment_info?.subscription_info
                                ?.monthly_amount_formatted
                            );
                          }}
                          className="dropdown-a"
                        >
                          {`Subscribe`}
                        </Link>
                      </Media>
                    ) : (
                      ""
                    )}
                  </>
                )}
                {isActiveTab === "subscribing" ? (
                  ""
                ) : (
                  <>
                    {post?.payment_info?.unsubscribe_btn_status === 1 && (
                      <Media as="li">
                        <Link
                          to="#"
                          // onClick={(event) => handleReportPost(event, post)}
                          onClick={() => handleUnfollowModalShow()}
                          className="dropdown-a"
                          style={{
                            color: "#ff6060",
                          }}
                        >
                          {t("Subscribing")}
                        </Link>
                      </Media>
                    )}
                  </>
                )}
                <Media as="li">
                  <Link
                    to="#"
                    onClick={(event) => handleBlockUser(event, post)}
                    className="dropdown-a"
                  >
                    {" "}
                    {t("add_to_blocklist_para")}
                  </Link>
                </Media>
                {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {/* <Link to={
          // {pathname : `/post/${post.post_unique_id}` , state : { data: "true" , from : "home", isActiveTab: props.isActiveTab}}
          "#"
         }
         > */}
        <div className="new-feed-body-sec">
          <AutoplaySlider
            organicArrows={true}
            bullets={post.postFiles.length > 1 ? true : false}
            play={false}
            cancelOnInteraction={false}
            interval={6000}
            mobileTouch={true}
            infinite={false}
          >
            {post.postFiles && post.postFiles.length > 0 ?
              post.postFiles.map((file, i) => 
                  file.file_type === "image" ? 
                    <div key={file?.post_file_id}>
                      {
                        post.is_paid_post === 1 ? 
                        <Link
                          to={"#"}
                          onClick={e =>
                            post.payment_info.post_payment_type === "ppv" ?
                              setPaymentModal(true)
                              : post.payment_info.post_payment_type === "subscription" ?
                                redirectToProfile()
                                : e.preventDefault()}
                        >
                          {/* <div>
                            <Image
                              className="new-feed-post-img"
                              src={file.post_file}
                            />
                          </div> */}
                          <div className="post-lock-icon-sec">
                            <Image
                              className="profile-lock-icon"
                              src={
                                window.location.origin + "/assets/images/new-home/icon/lock-icon.png"
                              }
                            />
                          </div>
                        </Link> : 
                        <Link
                          to={"#"}
                        >
                          <div>
                            <Image
                              className="new-feed-post-img"
                              src={file.post_file}
                            />
                          </div>
                        </Link>
                      }
                     </div>
                   : file.file_type === "video" ? 
                    <div key={file?.post_file_id}>
                    {
                      post.is_paid_post == 1 ? 
                      <Link
                          to={"#"}
                          onClick={e =>
                            post.payment_info.post_payment_type === "ppv" ?
                              setPaymentModal(true)
                              : post.payment_info.post_payment_type === "subscription" ?
                                redirectToProfile()
                                : e.preventDefault()}
                      >
                        <div className="post-video-icon-sec">
                          <Image
                            className="profile-video-icon"
                            src={
                              window.location.origin +
                              "/assets/images/new-home/icon/video-icon.png"
                            }
                          />
                        </div>
                        <div className="post-lock-icon-sec">
                            <Image
                              className="profile-lock-icon"
                              src={
                                window.location.origin + "/assets/images/new-home/icon/lock-icon.png"
                              }
                            />
                          </div>
                       </Link>
                      : file.video_preview_file ? (
                          <ReactPlayer url={file.video_preview_file} />
                        ) : (
                          <VideoComponent
                            src={file.post_file}
                            key={file.post_file_unique_id}
                          />
                        )
                    }
                    </div>
                  : file.file_type === "audio" ? 
                  <div key={file?.post_file_id}>
                    {
                      post.is_paid_post === 1 ? 
                      <Link
                        to={"#"}
                        onClick={e =>
                          post.payment_info.post_payment_type === "ppv" ?
                            setPaymentModal(true)
                            : post.payment_info.post_payment_type === "subscription" ?
                              redirectToProfile()
                              : e.preventDefault()}
                      >
                      <div>
                        <div className="post-lock-icon-sec">
                          <Image
                            className="profile-lock-icon"
                            src={
                              window.location.origin + "/assets/images/new-home/icon/lock-icon.png"
                            }
                          />
                        </div>
                        <div className="profile-video-icon-sec">
                          <Image
                            className="profile-audio-icon"
                            src={
                              window.location.origin +
                              "/assets/images/new-home/icon/audio-icon.png"
                            }
                          />
                        </div>
                        {/* <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' /> */}
                      </div>
                    </Link> :
                    <Link
                      to={"#"}
                    >
                      <div>
                        {file.audio_preview_file ? (
                          <ReactPlayer url={file.audio_preview_file} />
                        ) : (
                          <Image
                            className="new-feed-post-img"
                            src={
                              file.preview_file
                                ? file.preview_file
                                : file.post_file
                            }
                          />
                        )}
                        <div className="profile-video-icon-sec">
                          <Image
                            className="profile-audio-icon"
                            src={
                              window.location.origin +
                              "/assets/images/new-home/icon/audio-icon.png"
                            }
                          />
                        </div>
                        {/* <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' /> */}
                      </div>
                    </Link>
                    }
                  </div>
                   : null
              ) : null
            }
          </AutoplaySlider>
        </div>

        {/* </Link> */}

        <div className="new-feed-footer-sec">
          <div className="new-feed-footer-action-btn-sec">
            <div className="new-feed-footer-action-left-sec">
              <Button
                className="new-feed-wishlist-btn"
                onClick={(event) => handleLike()}
              >
                {post.is_user_liked ? (
                  <Image
                    className="new-feed-wishlist-icon"
                    src={
                      window.location.origin +
                      "/assets/images/feed-story/heart.svg"
                    }
                  />
                ) : (
                  <Image
                    className="new-feed-wishlist-icon"
                    src={
                      window.location.origin +
                      "/assets/images/feed-story/heart-outline.svg"
                    }
                  />
                )}
                <span>{post.like_count}</span>
              </Button>

              <Button
                className="new-feed-wishlist-btn"
                onClick={() => props.handleOpenAddCommentModal(post)}
              >
                <Image
                  className="new-feed-wishlist-icon"
                  src={
                    window.location.origin +
                    "/assets/images/feed-story/comments.svg"
                  }
                />
                <span>{post.total_comments}</span>
              </Button>
              <Button
                className="new-feed-wishlist-btn"
                onClick={() => {
                  props.setIsPostId(post.post_id);
                  props.handleOpenPostForwardModel();
                }}
              >
                <Image
                  className="new-feed-wishlist-icon"
                  src={
                    window.location.origin +
                    "/assets/images/feed-story/send.png"
                  }
                />
              </Button>
              {/*  <Dropdown className="forward-post-dropdown">
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="forward-post-dropdown-btn"
                  style={{background: "none", border: "none", justifyContent: "flex-start", padding:"0"}}
                >
                </Dropdown.Toggle>

                <Dropdown.Menu className="drop-d-m-forward">
                  <div className="drop-d-m-forward-wrapper">
                    <Media as="li">
                        <a href={`${shareUrl.facebook}=${post?.share_link}`}  target="_blank" >
                           <img className="drop-d-social-icon" src={window.location.origin + "/assets/images/new-settings/social-icons/facebook2.png"} alt="facebook-icon" />
                        </a>
                    </Media>
                    <Media as="li">
                        <a href={`${shareUrl.twitter}=${post?.share_link}`}   target="_blank" >
                           <img className="drop-d-social-icon" src={window.location.origin + "/assets/images/new-settings/social-icons/twitter2.png"} alt="twitter-icon" />
                        </a>
                    </Media>
                    <Media as="li">
                        <a href={`${shareUrl.whatsapp}=${post?.share_link}`}   target="_blank" >
                           <img className="drop-d-social-icon" src={window.location.origin + "/assets/images/new-settings/social-icons/whatsapp.png"} alt="whatsapp-icon" />
                        </a>
                    </Media>
                    <Media as="li">
                        <a href={`${shareUrl.messanger}=${post?.share_link}`}   target="_blank" >
                           <img className="drop-d-social-icon" src={window.location.origin + "/assets/images/new-settings/social-icons/messenger.png"} alt="messanger-icon" />
                        </a>
                    </Media>
                    <Media as="li">
                        <a href={`${shareUrl.pintrest}=${post?.share_link}`}   target="_blank" >
                           <img className="drop-d-social-icon" src={window.location.origin + "/assets/images/new-settings/social-icons/social.png"} alt="pintrest-icon" />
                        </a>
                    </Media>
                    <Media as="li">
                        <a href={`${shareUrl.reddit}=${post?.share_link}`}   target="_blank" >
                           <img className="drop-d-social-icon" src={window.location.origin + "/assets/images/new-settings/social-icons/reddit.png"} alt="reddit-icon" />
                        </a>
                    </Media>
                    <Media as="li">
                        <a href={`${shareUrl.vk}=${post?.share_link}`}   target="_blank" >
                           <img className="drop-d-social-icon" src={window.location.origin + "/assets/images/new-settings/social-icons/vk.png"} alt="vk-icon" />
                        </a>
                    </Media>
                    <Media as="li">
                        <a href={`${shareUrl.linkedin}=${post?.share_link}`}   target="_blank" >
                           <img className="drop-d-social-icon" src={window.location.origin + "/assets/images/new-settings/social-icons/linkedin.png"} alt="linkdin-icon" />
                        </a>
                    </Media>
                  </div>
                </Dropdown.Menu>
              </Dropdown> */}
            </div>
            <div className="new-feed-footer-action-right-sec">
              <Button
                className="new-feed-bookmark-btn"
                onClick={() => handleBookmark()}
              >
                {post.is_user_bookmarked === 1 ? (
                  <Image
                    className="new-feed-bookmark-icon"
                    src={
                      window.location.origin +
                      "/assets/images/feed-story/bookmark-fill.svg"
                    }
                  />
                ) : (
                  <Image
                    className="new-feed-bookmark-icon"
                    src={
                      window.location.origin +
                      "/assets/images/feed-story/bookmark-outline.svg"
                    }
                  />
                )}
              </Button>
            </div>
          </div>
          <div className="new-feeds-liked-by-users">
            {/* <h5>Liked by <span>Elvin</span> and <span>102 others</span></h5> */}
            <h5>{post.liked_by_formatted}</h5>
            {post.recent_likes && post.recent_likes.length > 0 ? (
              <div className="new-feeds-liked-users-img-sec">
                {post.recent_likes.map((likedUser, i) => (
                  <Image
                    key={i}
                    className="new-feeds-liked-users-img"
                    src={likedUser.picture}
                  />
                ))}
              </div>
            ) : null}
          </div>
          <div className="new-feed-post-description-sec">
            <p
              dangerouslySetInnerHTML={{
                __html: post.content_formatted,
              }}
            ></p>
          </div>
          {post.total_comments > 0 ? (
            <div className="new-feed-view-link-sec">
              <Link
                to="#"
                onClick={() => props.handleOpenAddCommentModal(post)}
              >
                {t("view_all")}
                <span> {post.total_comments}</span> {t("comments")}
              </Link>
            </div>
          ) : (
            <div className="new-feed-view-link-sec">
              {/* <Link to={`/post/${post.post_unique_id}`}>
                {t("add_comments")}
              </Link> */}
              <Link
                to="#"
                onClick={() => props.handleOpenAddCommentModal(post)}
              >
                {t("add_comments")}
              </Link>
            </div>
          )}
          <div className="new-feed-post-time-sec">
            <p>{post.created}</p>
          </div>
        </div>
      </div>
      {/* {
        sendTip ? <SendTipModal
          sendTip={sendTip}
          closeSendTipModal={closeSendTipModal}
          username={post.username}
          userPicture={post.user_picture}
          name={post.user_displayname}
          post_id={post.post_id}
          user_id={post.user_id}
        />
          : null
      } */}
      {subscrptionPayment ? (
        <SubscriptionPaymentModal
          paymentsModal={subscrptionPayment}
          closepaymentsModal={closePostPaymentModal}
          name={post.user.name}
          user_unique_id={post.user.user_unique_id}
          subscriptionData={subscriptionData}
          pageType={"userCard"}
        />
      ) : null}
      {showUnfollow && (
        <Modal
          show={showUnfollow}
          onHide={handleUnfollowModalClose}
          backdrop="static"
          keyboard={false}
          centered
          className={`${
            localStorage.getItem("theme") !== "" &&
            localStorage.getItem("theme") !== null &&
            localStorage.getItem("theme") !== undefined &&
            localStorage.getItem("theme") === "dark"
              ? "dark-theme-modal"
              : ""
          }
                  `}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("unsubscribe")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t("cancel_subscription_conformation")}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              size="lg"
              onClick={handleUnfollowModalClose}
            >
              {t("close")}
            </Button>
            <Button
              variant="primary"
              size="lg"
              onClick={(event) => handleUnSubscriber(event, post.user.user_id)}
            >
              {t("yes")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {paymentModal ?
        <PPVPaymentModal
          PPVPayment={paymentModal}
          closePPVPaymentModal={closePaymentModal}
          post={post}
          username={post.username}
          userPicture={post.user_picture}
          name={post.user_displayname}
          post_id={post.post_id}
          user_id={post.user_id}
          amount={post.amount}
          amount_formatted={post.amount_formatted}
        />
        : null
      }
      {sendTip ? (
        <SendTipPaymentModal
          paymentsModal={sendTip}
          closepaymentsModal={closeSendTipModal}
          post_id={post.post_id}
          user_id={post.user_id}
          type={post}
        />
      ) : null}
      {reportMode ? (
        <ReportModeModal
          reportMode={reportMode}
          closeReportModeModal={closeReportModeModal}
          post={post}
        />
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  posts: state.home.homePost,
  searchUser: state.home.searchUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(NewFeedDisplayCard));
