import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import "./BecomeAContentCreator.css";
import { t, translate } from "react-multi-lang";
import { connect, useSelector } from "react-redux";
import { Multiselect } from "multiselect-react-dropdown";
import { fetchPostCategoriesStart } from "../../store/actions/PostAction";
import {
  addPrimaryInfoStart,
  setOtpStart,
  verifyOtpStart,
} from "../../store/actions/KycDocumentAction";
import { Formik } from "formik";
import * as Yup from "yup";
import configuration from "react-global-configuration";
import { referralValidationStart } from "../../store/actions/UserAction";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify";


const Step8 = (props) => {
  const {
    sentOtp: { data: sentOtpData, loading: sentLoading },
    verifyOtp: { data: verifyData, loading: verifyOtpLoading },
  } = useSelector((state) => state.kycDocument);
  const [isCategoriesSelected, setIsCategoriesSelected] = useState([]);
  const [timer, setTimer] = useState(59);
  const [showTimer, setShowTimer] = useState(false);
  const [mobileNo, setMobileNo] = useState(null);
  const [otp, setOtp] = useState(null);

  const [referralCode, setReferralCode] = useState("");

  const isSendButtonDisabled = showTimer;

  const profileDataSchemachema = Yup.object().shape({
    full_name: Yup.string().required("Fullname is required"),
    gender: Yup.string().required("Gender is required"),
    mobile: Yup.string().required("Mobile number is required"),
    address: Yup.string().required("Address is required"),
  });

  const handleSelectList = (selectedList) => {
    if (selectedList.length <= 3) {
      setIsCategoriesSelected(selectedList);
    }
  };

  useEffect(() => {
    props.dispatch(fetchPostCategoriesStart());
  }, []);

  const startTimer = () => {
    // setShowTimer(true);

    props.dispatch(
      setOtpStart({ setShowTimer: setShowTimer, mobile: mobileNo })
    );
  };

  useEffect(() => {
    let intervalId;
    if (showTimer && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setShowTimer(false);
    }

    return () => clearInterval(intervalId);
  }, [timer, showTimer]);

  // const checkReferralCode = (event) => {
  //   event.preventDefault();

  //   if (referralCode) {
  //     props.dispatch(referralValidationStart({ referral_code: referralCode }));
  //   } else {
  //     const notificationMessage = getErrorNotificationMessage(
  //       t("please_enter_the_referral_code")
  //     );
  //     props.dispatch(createNotification(notificationMessage));
  //   }
  // };

  return (
    <div>
      <div className="step-5-payout-method-sec">
        <div className="step-5-payment-method">
          {/* <h5>Bank Transfer</h5> */}
          <Formik
            validationSchema={profileDataSchemachema}
            initialValues={{
              full_name: "",
              gender: "",
              mobile: "",
              address: "",
            }}
            onSubmit={(values) => {
              const allSelectedCategoriesId = isCategoriesSelected.map(
                (ele) => ele.category_id
              );

              // console.log({...values , mobile : values.mobile.toString() ,category_id : allSelectedCategoriesId.toString()}, "values");

              props.dispatch(
                addPrimaryInfoStart({
                  info: {
                    ...values,
                    mobile: values.mobile.toString(),
                    category_id: allSelectedCategoriesId.toString(),
                  },
                  jumpToStep: props.jumpToStep,
                  referral_code: referralCode,
                })
              );
            }}
          >
            {({ handleChange, values, errors, handleSubmit, touched }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formHorizontalNickname">
                      <Form.Label>Full name: (*)</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={"Full name"}
                        // value={inputData.route_number}
                        value={values.full_name}
                        onChange={handleChange}
                        name="full_name"
                        // onChange={(event) => {
                        //   setInputData({
                        //     ...inputData,
                        //     route_number: event.currentTarget.value,
                        //   });
                        // }}
                      />
                      {errors.full_name && touched.full_name && (
                        <p className="error-msg-step-8">{errors.full_name}</p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group
                      controlId="formHorizontalAccountNumber"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Form.Label>Gender: (*)</Form.Label>

                      <select
                        name="gender"
                        id="gender-id"
                        value={values.gender}
                        onChange={handleChange}
                        style={{
                          height: "32px",
                          fontSize: "14px",
                          border: "1px solid #ced4da",
                          color: "#979da6",
                          padding: "4px",
                        }}
                      >
                        <option value="">Select gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>

                      {errors.gender && touched.gender && (
                        <p className="error-msg-step-8">{errors.gender}</p>
                      )}
                    </Form.Group>
                  </Col>

                  {/* <Col md={6}>
                <Form.Group controlId="formHorizontalFirstname" style={{display: "flex", flexDirection: "column", }}>
                  <Form.Label>Address: (*)</Form.Label>
                 <textarea rows={5} name="address" id="address-id" placeholder='Enter address...' style={{border: "1px solid rgb(206, 212, 218)", outline: "none", fontSize: "12px", padding: "5px 12px"}} />
                </Form.Group>
              </Col> */}
                  {/* <Col md={6}>
                    <Form.Group className="mb-0">
                      <Form.Label>CATEGORY (OPTIONAL)</Form.Label>
                      {props.postCategories.data.post_categories ? (
                        <Multiselect
                          name="category_ids"
                          selectedValues={isCategoriesSelected}
                          options={props.postCategories.data.post_categories}
                          displayValue="name"
                          avoidHighlightFirstOption="true"
                          placeholder={t("choose_category")}
                          onSelect={handleSelectList}
                          onRemove={handleSelectList}
                          selectionLimit={3}
                        />
                      ) : null}
                    </Form.Group> */}
                    {/* <Form.Group controlId="formHorizontalLastname">
                  <Form.Label>{t("last_name")}: (*)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("last_name")}
                    // value={inputData.last_name}
                    name="last_name"
                    // onChange={(event) => {
                    //   setInputData({
                    //     ...inputData,
                    //     last_name: event.currentTarget.value,
                    //   });
                    // }}
                  />
                </Form.Group> */}
                  {/* </Col> */}
                  <Col md={6}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <div>
                        <Form.Label> Mobile No : (*)</Form.Label>

                        <Form.Control
                          type="number"
                          placeholder={"Number"}
                          className="mobile_num_c"
                          // value={inputData.route_number}
                          name="mobile"
                          onChange={(e) => {
                            setMobileNo(e.target.value);
                            handleChange(e);
                          }}
                          value={values.mobile}
                          // onChange={(event) => {
                          //   setInputData({
                          //     ...inputData,
                          //     route_number: event.currentTarget.value,
                          //   });
                          // }}
                        />

                        {errors.mobile && touched.mobile && (
                          <p className="error-msg-step-8">{errors.mobile}</p>
                        )}
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            marginTop: "7px",
                            alignItems: "center",
                          }}
                        >
                          <button
                            disabled={
                              isSendButtonDisabled ||
                              sentLoading ||
                              !Boolean(values.mobile)
                            }
                            className="sent-otp-btn-step-8"
                            onClick={() => startTimer()}
                            type="button"
                            style={{
                              border: "none",
                              background: "#6671e9",
                              color: "white",
                              padding: "6px 14px",
                            }}
                          >
                            {sentLoading ? "Loading..." : "Send Otp"}
                          </button>
                          {showTimer ? (
                            <span
                              style={{ fontSize: "15px", color: "#8a96a3" }}
                            >
                              {timer}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      {sentOtpData?.success ? (
                        <div
                          style={{
                            display: "flex",
                            gap: "4px",
                            marginTop: "12px",
                          }}
                        >
                          <input
                            readOnly={verifyData.success ? true : false}
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            type="number"
                            name="otp"
                            placeholder="Enter otp..."
                            style={{ width: "32%", fontSize: "12px" }}
                          />
                          <button
                            disabled={verifyData.success || verifyOtpLoading}
                            className="sent-otp-btn-step-8"
                            onClick={() =>
                              props.dispatch(verifyOtpStart({ otp: otp }))
                            }
                            type="button"
                            style={{
                              border: "none",
                              background: "#6671e9",
                              color: "white",
                              padding: "6px 14px",
                            }}
                          >
                            {verifyOtpLoading ? "Loading..." : "Verify Otp"}{" "}
                          </button>
                        </div>
                      ) : (
                        ""
                      )}

                      <div></div>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group
                      controlId="formHorizontalFirstname"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Form.Label>Address: (*)</Form.Label>
                      <textarea
                        onChange={handleChange}
                        value={values.address}
                        rows={5}
                        name="address"
                        id="address-id"
                        placeholder="Enter address..."
                        style={{
                          border: "1px solid rgb(206, 212, 218)",
                          outline: "none",
                          fontSize: "12px",
                          padding: "5px 12px",
                        }}
                      />
                    </Form.Group>

                    {errors.address && touched.address && (
                      <p className="error-msg-step-8">{errors.address}</p>
                    )}
                  </Col>

                  {/* <Col md={6}>
                    <Form.Group
                      controlId="formHorizontalFirstname"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Form.Label>{t("refferal_st")}</Form.Label>
                      
                      {configuration.get(
                        "configData.is_referral_enabled"
                      ) == 1 ? (
                        <>
                          <div class="form-group">
                            <input
                              type="text"
                              name="referral_code"
                              placeholder={t("referral_code_optional")}
                              value={referralCode}
                              className="form-control mb-3"
                              onChange={(e) =>
                                setReferralCode(e.target.value)
                              }
                            /> */}
                            {/* <ErrorMessage
                              component={"div"}
                              name="referral_code"
                              className="text-danger text-right"
                            /> */}
                            {/* <div className="check-referral-link">
                              <a
                                className="text-primary"
                                href="#"
                                onClick={checkReferralCode}
                              >
                                {t("check_referral_code_valid")}
                              </a>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </Form.Group>
                  </Col> */}

                </Row>

                <div className="edit-save">
                  {/* <Button
                className="btn gradient-btn gradientcolor addBank"
                type="submit"
                disabled={props.addBankAccount.buttonDisable}
              >
                {props.addBankAccount.loadingButtonContent !== null
                  ? props.addBankAccount.loadingButtonContent
                  : "Next"}
              </Button> */}
                  <Button
                    className="btn gradient-btn gradientcolor addBank"
                    type="submit"
                    // disabled={props.addBankAccount.buttonDisable}
                  >
                    {/* {props.addBankAccount.loadingButtonContent !== null
                  ? props.addBankAccount.loadingButtonContent
                  : "Next"} */}
                    Next
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  bankAccount: state.bankAccount.bankAccount,
  addBankAccount: state.bankAccount.addBankAccountInput,
  postCategories: state.post.postCategories,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(Step8));
