import React, { useState } from 'react'
import { Form, Button, Image, Modal, Tab, Row, Col, Nav, FormCheck, InputGroup } from "react-bootstrap";

export const RejectppvRequest = (props) => {


	const handleSubmit = (e) => {
		e.preventDefault()

		let newStatus = 0
		
		if (props.message === '' || props.message) {
			newStatus = 2
		}

		setTimeout(() => {	
			if (newStatus) {
				props.handleMessageSubmit({msgAmount: 0, ppvRejectStatus: newStatus})
				props.setRejectPpvModel(false)
			}
			
		}, 400)
	}

	const handleRejectPpvContentSubmit = (e) => {
		e.preventDefault()

		props.handleMessageSubmit({msgAmount: 0, ppvRejectStatus: 5})
		props.closeRejectPpvContent()
	}

	const closeRejectModal = props.rejectPpvContent ? props.closeRejectPpvContent : props.closeRejectPpvModal

	return (
		<>
			<Modal
        className="modal-dialog-center chat-upload-modal"
        size="md"
        centered
        show={props.rejectPpvModel || props.rejectPpvContent}
        onHide={closeRejectModal}
      >
        <Button className="modal-close" onClick={() => {
					props.closeRejectPpvModal()
					props.closeRejectPpvContent()
				}}>
          <Image
            className="close-icon"
            src={
              window.location.origin + "/assets/images/new-chat/modal-close.svg"
            }
          /> 
        </Button>
        <Modal.Body>
          <>
						<div 
							className="ppv-content-request-container"
							// onSubmit={handleSubmit} 
							style={{
								gap: "9px"
							}}
						>
							{				
								props.rejectPpvContent 
									? (
										<>
											<div
												style={{
													marginTop: "25px",
													padding: "15px",
													border: "1px solid #ac98988c",
													borderRadius: "6px"
												}}		
											>
												<h2
													style={{
														fontSize: "17px"
													}}
												>
													If you reject the PPV content, you will only be able to send the PPV request again after a waiting period of three days.
												</h2>
											</div>
											<button
												// type="submit"
												className="send-request-btn"
												onClick={handleRejectPpvContentSubmit}
											>
												Reject
											</button>
										</>
									)
									: (
										<>
											<div className='reject-ppv-request'>  
												<label htmlFor="rejectPpvRequest">Reject with reason (optional)</label>
												<textarea
													name="rejectPpvRequest"
													value={props.message}
													onChange={(e) => props.setMessage(e.target.value)}
													id="rejectPpvRequest"
													placeholder="Share reject reason..."
													className="reject-ppv-input"
												/>
											</div>
											<button
												// type="submit"
												className="send-request-btn"
												onClick={handleSubmit}
											>
												Reject
											</button>
										</>
									)
							}
						</div>
          </>
        </Modal.Body>
      </Modal>
		</>
	)
}
