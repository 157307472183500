import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Image, InputGroup, FormControl } from "react-bootstrap";
import { searchUserStart } from "../../../store/actions/HomeAction";
import { Link } from "react-router-dom";
import VerifiedBadgeNoShadow from "../../Handlers/VerifiedBadgeNoShadow";
import { translate, t } from "react-multi-lang";
import { debounce } from "lodash";
import "./NewHomeSearch.css";

const NewHomeSearch = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [show, toggleShow] = useState(false);
  const wrapperRef = useRef(null);

  const handleSearch = debounce((value) => {
    if (value.trim() === "") {
      toggleShow(false);
    } else {
      toggleShow(true);
      props.dispatch(searchUserStart({ key: value }));
    }
  }, 300);

  const handleInputChange = (event) => {
    const value = event.target.value;
    props.onSearchChange(value); // Notify the parent of input change
    handleSearch(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      toggleShow(false);
      console.log("Enter key pressed");
      // Optionally, you can trigger a search action here
      props.handleSelectSubmit();
    }
  };

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        toggleShow(false);
      }
    };

    window.addEventListener("resize", handleWindowResize);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderSearch = () => (
    <div
      className="new-feed-search-sec"
      style={{ position: "relative", padding: "0.5em" }}
      ref={wrapperRef}
    >
      <InputGroup className="mb-0">
        <FormControl
          placeholder={t("search")}
          aria-describedby="basic-addon2"
          onChange={handleInputChange}
          value={props.searchValue} // Use parent's state
          onKeyDown={handleKeyDown}
        />
        <InputGroup.Text id="basic-addon2" onClick={props.handleSelectSubmit}>
          <Image
            style={{ cursor: "pointer" }}
            className="new-feeds-search-icon"
            src={`${window.location.origin}/assets/images/feed-story/search-icon.svg`}
          />
        </InputGroup.Text>
      </InputGroup>

      {show && (
        <div
          className="search-dropdown-sec"
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "4px",
            zIndex: 1000,
            maxHeight: "300px",
            overflowY: "auto",
            padding: "0.5em",
            width: "auto"
          }}
        >
          <ul className="list-unstyled search-dropdown-list-sec">
            {props.searchUser.loading ? (
              <p>Loading...</p>
            ) : props.searchUser.data ? (
              props.searchUser.data.users && props.searchUser.data.users.length > 0 ? (
                props.searchUser.data.users.map((user) => (
                  <li key={user.user_unique_id}>
                    <Link to={`/${user.user_unique_id}`}>
                      <div className="search-body">
                        <div className="user-img-sec">
                          <Image
                            alt="#"
                            src={user.picture}
                            className="user-img"
                            style={{ width: "50px", height: "50px", objectFit: "cover" }}
                          />
                        </div>
                        <div className="search-content">
                          <h5>
                            {user.name}{" "}
                            {user.is_verified_badge === 1 && <VerifiedBadgeNoShadow />}
                          </h5>
                          <p className="text-muted f-12">@{user.username}</p>
                        </div>
                      </div>
                    </Link>
                  </li>
                ))
              ) : props.searchUser.data.posts && props.searchUser.data.posts.length > 0 ? (
                props.searchUser.data.posts.map((post) => (
                  <li key={post.post_id}>
                    <div className="search-body">
                      <div className="user-img-sec">
                        <Image
                          alt="#"
                          src={post.postFiles[0]?.post_file}
                          className="post-img"
                          style={{ width: "100px", height: "100px", objectFit: "cover" }}
                        />
                      </div>
                      <div className="search-content">
                        <p>{post.content_formatted}</p>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <p>{t("no_data_found")}</p>
              )
            ) : (
              <p>{t("no_data_found")}</p>
            )}
          </ul>
        </div>
      )}
    </div>
  );

  if (width < 768) return <div className="mobile-visible">{renderSearch()}</div>;
  if (width > 768 && props.desktop) return <>{renderSearch()}</>;

  return null;
};

const mapStateToProps = (state) => ({
  searchUser: state.home.searchUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate(NewHomeSearch));
