import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
	getSuccessNotificationMessage,
	getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import { checkLogoutStatus } from "../actions/ErrorAction";
import { createNotification } from "react-redux-notify";
import { fetchFormerSubscriberFailure, fetchFormerSubscriberSuccess } from "../actions/FormerSubscriberAction";
import { FETCH_FORMER_SUBSCRIBER_START } from "../actions/ActionConstant";
import { addUserListSuccessD } from "../actions/CustomListAction";

function* fetchAllFormerSubscriber({data}) {
	try {
		if(data !== undefined && data.setIsLoadingList !== undefined){

			data.setIsLoadingList(true);
		}

		const filterSortPayload = data?.filterSort ? data?.filterSort : {
			filter: '',
			sort: '',
			tag_title: '',
			tag_colour: '',
			category: "",
		  }

		const response = yield api.postMethod("former_subscribers", filterSortPayload);

		if (response.data?.success) {
			if(data !== undefined && data.setIsLoadingList !== undefined){

				data.setIsLoadingList(false);
			}

			const updatedFormerSubscriberData = response.data?.data?.subscriber?.map((item) => {
				return {
					...item,
					isSelected: false
				}
			})

			yield put(fetchFormerSubscriberSuccess({subscriber: updatedFormerSubscriberData, total: response.data?.data?.total}));

			// yield put(fetchFormerSubscriberSuccess(response.data?.data));
             
			if(data !== undefined && data.allListData !== undefined){

				const updatedData = data.allListData.map((ele)=>{
				   if(ele.list_name === data.typeList){
	  
					const subscriberFlagData = response?.data?.data?.subscriber.map((ele)=> {
					  return {
						...ele,
						  customListFlag : "FormerSubsciberFromList"
					  }
					})
					
					return {
						 ...ele,
						 data : subscriberFlagData,
						 total : response?.data?.data?.total
					}
		  
				   }else{
					return ele;
				   }
		  
				})
				yield put(addUserListSuccessD(updatedData));
			
			   }

		} else {
			if(data !== undefined && data.setIsLoadingList !== undefined){

				data.setIsLoadingList(false);
			}
			yield put(fetchFormerSubscriberFailure(response.data.error));
			const notificationMessage = getErrorNotificationMessage(
				response.data.error
			);
			yield put(checkLogoutStatus(response.data));
			yield put(createNotification(notificationMessage));
		}		
	} catch (error) {
		if(data !== undefined && data.setIsLoadingList !== undefined){

			data.setIsLoadingList(false);
		}
		yield put(fetchFormerSubscriberFailure(error));
		const notificationMessage = getErrorNotificationMessage(error.message);
		yield put(createNotification(notificationMessage));
	}
}

export default function* pageSaga() {
	yield all([yield takeLatest(FETCH_FORMER_SUBSCRIBER_START, fetchAllFormerSubscriber)]);  
}