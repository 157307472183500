import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import UserCard from "../FansFollowing/UserCard";
import { connect, useDispatch } from "react-redux";
import { fetchFavStart } from "../../../store/actions/FavAction";
import NoDataFound from "../../NoDataFound/NoDataFound";
import useInfiniteScroll from "../../helper/useInfiniteScroll";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import FollowingLoader from "../../Loader/FollowingLoader";
import SearchInput from "../Searchinput/SearchInput";
import FavoritesListView from "./FavoritesListView";
import FavoritesActiveSec from "./FavoritesActiveSec";
import { getSubscriptionLevelsStart } from "../../../store/actions/SubscriptionAction";
import AddUserCustomFavourite from "../AddUserCustomFavourite/AddUserCustomFavourite";
import RemoveUserFromList from "../RemoveUserFromList/RemoveUserFromList";
import ColorTagCustomizeModal from "../ColorTagCustomization/ColorTagCustomizeModal";
import FilterSortingListUsers from "../FilterListUser/FilterSortingListUsers";

const FavoritesIndex = (props) => {
  const [noMoreData, setNoMoreData] = useState(false);
  const [list, setList] = useState(true);
  const [activeSec, setActiveSec] = useState("active-sec");
  const [userData, setUserData] = useState(props.fav?.data?.favs);
  const [subLevelOp, setSubLevelOp] = useState([]);
  const [selectedTip, setSelectedTip] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterLevel, setFilterLevel] = useState([]);

  const [isSelected, setIsSelected] = useState(false);
  const [levelFilter, setLevelFilter] = useState([]);

  const [isSelectedFavourite, setIsSelectedFavourite] = useState(false);
  const [checkedUserId, setCheckedUserId] = useState([]);
  const [isAddUser, setIsAddUser] = useState(false);

  const [isRemoveUser, setIsRemoveUser] = useState(false);
  const [isTagUser, setIsTagUser] = useState(false);

  const [isSelectedUserCate, setIsSelectedUserCate] = useState([]);
  const [selectedColorTags, setSelectedColorTags] = useState(null);
  const [sortUsers, setSortUsers] = useState("");
	const [sortingToogle, setSortingToogle] = useState({
    aToz : false,
    upToDown : false
  })

  const dispatch = useDispatch();

  const [shouldReload, setShouldReload] = useState(false); // New state for controlling reload
  useEffect(() => {
    props.dispatch(
      fetchFavStart({
        skip: props.fav.skip,
        filterSort: {
          filter: isSelectedUserCate?.map((elem) => elem.payload).toString() || "",
          sort: sortUsers || "",
          tag_title: selectedColorTags?.name || "",
          tag_colour: selectedColorTags?.color_tag_code || "",
          category:
          sortUsers === "username"
          ? sortingToogle.aToz
          ? "ASC"
          : "DESC"
          : sortingToogle.upToDown
          ? "ASC"
          : "DESC",
        },
      })
    );
    dispatch(getSubscriptionLevelsStart({ setFiltreTip: setSubLevelOp }));
  }, [shouldReload, isSelectedUserCate, sortingToogle, sortUsers, selectedColorTags]);

  const toggleReload = () => setShouldReload(!shouldReload);

  useEffect(() => {
    setUserData(props.fav?.data?.favs);
  }, [props.fav?.data?.favs]);

  const handleFilterUserData = (searchItem) => {
    if (levelFilter.length > 0) {
      const userLevelFilter = props.fav?.data?.favs.filter((item) => {
        return levelFilter.find(
          (elem) => elem === item?.fav_user?.subscription_level
        );
      });

      const newUserLevelFilter = userLevelFilter.filter((user) =>
        user?.fav_user?.name.toLowerCase().includes(searchItem.toLowerCase())
      );

      if (newUserLevelFilter.length > 0) {
        setLoading(true);
        setFilterLevel(newUserLevelFilter);
      }

      if (newUserLevelFilter.length == 0) {
        setLoading(false);
      }
      return;
    } else {
      const filterUserData = props.fav?.data?.favs.filter((user) => {
        return user?.fav_user?.name
          .toLowerCase()
          .includes(searchItem.toLowerCase());
      });
      setUserData(filterUserData);
    }
  };

  const handleSelectTip = (data) => {
    setIsSelected(true);

    setLevelFilter((prev) => [...prev, data.alias_of_level]);
    setSelectedTip([...selectedTip, data]);

    const filterSubscriberLevel = props.fav?.data?.favs.filter((user) => {
      return [...selectedTip, data].filter(
        (item) => user?.fav_user?.subscription_level === item?.alias_of_level
      )?.length
        ? true
        : false;
    });

    if (selectedTip.length > 0 && filterSubscriberLevel.length == 0) {
      setLoading(true);
    }

    const loadingBoolean =
      filterSubscriberLevel.length == 1
        ? true
        : selectedTip.length > 0 && filterSubscriberLevel.length == 0
        ? true
        : filterSubscriberLevel.length == 0
        ? false
        : true;

    if (loadingBoolean || !loadingBoolean) {
      setLoading(loadingBoolean);
    }

    setFilterLevel(filterSubscriberLevel);
  };

  const handleRemoveTip = (data) => {
    setIsSelected(false);
    const filterSubLevel = levelFilter.filter(
      (elem) => elem !== data?.alias_of_level
    );
    setLevelFilter(filterSubLevel);
    let updatedUserList = selectedTip.filter((item) => item.id !== data.id);
    setSelectedTip(updatedUserList);

    const filterSubscriberLevel = props.fav?.data?.favs.filter((user) => {
      return updatedUserList.filter(
        (item) => user?.fav_user?.subscription_level === item?.alias_of_level
      )?.length
        ? true
        : false;
    });

    if (filterSubLevel.length === 0) {
      setLoading(true);
    }

    setTimeout(() => {
      setFilterLevel(filterSubscriberLevel);
    }, 500);
  };

  const getFavouriteUserId = (id) => {};
  const [sendTip, setSendTip] = useState(false);

  const closeSendTipModal = () => {
    setSendTip(false);
  };

  const handleList = () => {
    setList(false);
  };

  const handleSelectAllUser = (e) => {
    const allSelectedFavouriteUser = userData?.map((item) => {
      return {
        ...item,
        fav_user: {
          ...item.fav_user,
          isSelected: e.target.checked,
        },
      };
    });

    if (e.target.checked === true) {
      const allSelectedCheckedUser = userData?.map(
        (elem) => elem?.fav_user?.user_id
      );
      setCheckedUserId(allSelectedCheckedUser);
    }

    if (e.target.checked === false) {
      setCheckedUserId([]);
    }

    setUserData(allSelectedFavouriteUser);
  };

  const handleIsSelected = () => {
    setIsSelectedFavourite((prev) => !prev);

    const allSelectedFavouriteUser = userData?.map((item) => {
      return {
        ...item,
        fav_user: {
          ...item.fav_user,
          isSelected: false,
        },
      };
    });
    setCheckedUserId([]);
    setUserData(allSelectedFavouriteUser);
  };

  const handleOpenAddUserModal = () => setIsAddUser(true);
  const handleCloseAddUserModal = () => setIsAddUser(false);

  const handleOpenRemoveUserModal = () => setIsRemoveUser(true);
  const handleCloseRemoveUserModal = () => setIsRemoveUser(false);

  const handleOpenRemoveUserTagModal = () => setIsTagUser(true);
  const handleCloseRemoveUserTagModal = () => setIsTagUser(false);

  let usersFilters = [
    { name: "All users", id: 1, label: "All users" , payload : "all_users" },
    { name: "All creators", id: 2, label: "All creators" , payload : "all_creator" },
    { name: "Star creator", label: "Star creator", payload: "" },
    { name: "Certified creator", id: 4, label: "Certified creator", payload : "all_certified" },
    { name: "Creator", id: 5, label: "Creator", payload : "creator" },
    { name: "All Non-creator", id: 6, label: "All Non-creator" , payload: "all_non_creator" },
    { name: "Suscribers", id: 7, label: "Suscribers" , payload: "subscriber" },
    { name: "Followers", id: 8, label: "Followers" , payload : "followers" },
    { name: "By color tag", id: 9, label: "By color tag" , payload: "by_color_tag" },
  ];
  
  return (
    <div className="lists">
      <Container>
        <Row>
          <Col sm={12} md={12} xs={12}>
            <div className="profile-post-area">
              <div 
                className="bookmarkes-list bookmarks-right-side"
                style={{width: "35%"}}  
              >
                <div className="pull-left">
                  <h3>
                    <Link className="bookmarkes-list" to={"/list"}>
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/icons/back.svg"
                        }
                        className="svg-clone"
                        width=""
                      />
                      {t("favorites")}
                    </Link>
                  </h3>
                </div>
              </div>
              <div className="search-component"
                style={{gap: "10px"}}
              >
                <SearchInput handleFilterUserData={handleFilterUserData} />
                <FilterSortingListUsers
                  isSelectedUserCate={isSelectedUserCate}
                  setIsSelectedUserCate={setIsSelectedUserCate}
                  selectedColorTags={selectedColorTags}
                  setSelectedColorTags={setSelectedColorTags}
                  sortUsers={sortUsers}
                  setSortUsers={setSortUsers}
                  sortingToogle={sortingToogle}
                  setSortingToogle={setSortingToogle}
                  usersFilters={usersFilters}
                />
                <div className="list__view__container">
                  <div className="list-icons" onClick={() => setList(true)}>
                    <svg
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-microsoft"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="#6661e7"
                        d="M7.462 0H0v7.19h7.462zM16 0H8.538v7.19H16zM7.462 8.211H0V16h7.462zm8.538 0H8.538V16H16z"
                      />
                    </svg>
                  </div>
                  <div className="list-icons" onClick={handleList}>
                    <svg
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-list-task"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="#6661e7"
                        fillRule="evenodd"
                        d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5zM3 3H2v1h1z"
                      />
                      <path
                        fill="#6661e7"
                        d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1z"
                      />
                      <path
                        fill="#6661e7"
                        fillRule="evenodd"
                        d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5zM2 7h1v1H2zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm1 .5H2v1h1z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="select-button-container"
              style={{
                paddingBottom: "10px",
              }}
            >
              {userData.length > 0 ? (
                <button className="select-btn" onClick={handleIsSelected}>
                  {isSelectedFavourite ? t("Unselect") : t("Select")}
                </button>
              ) : (
                ""
              )}

              {isSelectedFavourite && (
                <label htmlFor="select-all-input" className="select-all-user">
                  <input
                    type="checkbox"
                    checked={userData?.every(
                      (elem) => elem?.fav_user?.isSelected
                    )}
                    onChange={(e) => handleSelectAllUser(e)}
                    className="select-all-input"
                    id="select-all-input"
                  />
                  {t("Select_all")}
                </label>
              )}

              {userData?.some((elem) => elem?.fav_user?.isSelected) && (
                <button
                  className="Add-to-custom-favourite-list"
                  onClick={handleOpenAddUserModal}
                >
                  Add to
                </button>
              )}

              {userData?.some((elem) => elem?.fav_user?.isSelected) ? (
                <>
                  <button
                    className="remove__from__list"
                    onClick={handleOpenRemoveUserModal}
                  >
                    Remove from list
                  </button>

                  <button
                    className="color__tag__user"
                    onClick={handleOpenRemoveUserTagModal}
                  >
                    Tag by
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                gap: "5px",
                justifyContent: "space-between",
              }}
            >
              <div
                className="chips-container-list"
                style={{ display: "flex", gap: "5px" }}
              >
                {subLevelOp.length > 0 && (
                  <div className="active-chips-list-wrapper">
                    {subLevelOp.map((userL) => {
                      return (
                        <div
                          className={
                            levelFilter.includes(userL.alias_of_level)
                              ? `active-chips-list selected-active-tips`
                              : `active-chips-list`
                          }
                          onClick={() =>
                            levelFilter.includes(userL.alias_of_level)
                              ? ""
                              : handleSelectTip(userL)
                          }
                        >
                          <div className="active-list-icon-text-wrapper">
                            <img
                              style={{ height: "20px", width: "20px" }}
                              src={userL?.badge}
                              alt=""
                            />
                            <p style={{ margin: "0", fontSize: "13px" }}>
                              {userL?.alias_of_level}
                            </p>
                          </div>
                          {levelFilter.includes(userL.alias_of_level) ? (
                            <div
                              className="active-list-count-delete-w"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <p style={{ margin: "0", fontSize: "13px" }}>
                                ({userL?.total_user})
                              </p>
                              <button
                                className="active-list-count-delete-btn"
                                onClick={() => handleRemoveTip(userL)}
                              >
                                X
                              </button>
                            </div>
                          ) : (
                            <p style={{ margin: "0", fontSize: "13px" }}>
                              ({userL?.total_user})
                            </p>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              {levelFilter?.length > 0 && (
                <button
                  onClick={() => {
                    setLevelFilter([]);
                    setSelectedTip([]);
                    setFilterLevel([]);
                    setLoading(true);
                    dispatch(
                      getSubscriptionLevelsStart({
                        setFiltreTip: setSubLevelOp,
                      })
                    );
                  }}
                  style={{
                    all: "unset",
                    color: "#6661e7",
                    fontSize: "14px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  Reset all
                </button>
              )}
            </div>
            <div className="listing-tab">
              <div className="tab" role="tabpanel">
                <div className="tab-content tabs">
                  {loading ? (
                    levelFilter.length > 0 ? (
                      list ? (
                        <FavoritesActiveSec
                          activeSec={activeSec}
                          setActiveSec={setActiveSec}
                          favorites={props.fav}
                          userData={filterLevel}
                          pagePath={"favorite"}
                          isSelected={isSelectedFavourite}
                          setUserData={setUserData}
                          setCheckedUserId={setCheckedUserId}
                          checkedUserId={checkedUserId}
                        />
                      ) : (
                        <FavoritesListView
                          activeSec={activeSec}
                          setActiveSec={setActiveSec}
                          favorites={props.fav}
                          userData={filterLevel}
                        />
                      )
                    ) : list ? (
                      <FavoritesActiveSec
                        activeSec={activeSec}
                        setActiveSec={setActiveSec}
                        favorites={props.fav}
                        userData={userData}
                        pagePath={"favorite"}
                        isSelected={isSelectedFavourite}
                        setUserData={setUserData}
                        setCheckedUserId={setCheckedUserId}
                        checkedUserId={checkedUserId}
                      />
                    ) : (
                      <FavoritesListView
                        activeSec={activeSec}
                        setActiveSec={setActiveSec}
                        favorites={props.fav}
                        userData={userData}
                      />
                    )
                  ) : (
                    <NoDataFound></NoDataFound>
                  )}
                </div>
              </div>
            </div>
          </Col>
          {isAddUser && (
            <AddUserCustomFavourite
              isAddUser={isAddUser}
              handleCloseAddUserModal={handleCloseAddUserModal}
              checkedUserId={checkedUserId}
              setIsSelected={setIsSelectedFavourite}
              setUserData={setUserData}
              userData={userData}
              setCheckedUserId={setCheckedUserId}
              favouriteType={"favouriteType"}
            />
          )}

          {isRemoveUser && (
            <RemoveUserFromList
              isRemoveUser={isRemoveUser}
              handleCloseRemoveUserModal={handleCloseRemoveUserModal}
              checkedUserId={checkedUserId}
              setIsSelected={setIsSelectedFavourite}
              setUserData={setUserData}
              userData={userData}
              setCheckedUserId={setCheckedUserId}
              listType={"favourite"}
              userList={"Favorites"}
              removeType={"Remove"}
            />
          )}

          {isTagUser && (
            <ColorTagCustomizeModal
              isTagUser={isTagUser}
              handleCloseRemoveUserTagModal={handleCloseRemoveUserTagModal}
              checkedUserId={checkedUserId}
              setIsSelected={setIsSelectedFavourite}
              setUserData={setUserData}
              userData={userData}
              setCheckedUserId={setCheckedUserId}
              listType={"favorites"}
              onActionSuccess={toggleReload}
            />
          )}
        </Row>
      </Container>
    </div>
  );
};

const mapStateToPros = (state) => ({
  fav: state.fav.fav,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(FavoritesIndex));
