import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import UserCard from "../UserCard";
import { translate, t } from "react-multi-lang";
import NoDataFound from "../../../NoDataFound/NoDataFound";
import FollowingLoader from "../../../Loader/FollowingLoader";
import { fetchListsDetailsStart } from "../../../../store/actions/HomeAction";
import { useDispatch } from "react-redux";

const FollowingActiveSec = (props) => {
  const dispatch = useDispatch();
  const [sortedUserData, setSortedUserData] = useState([]);

  // Fetch list details on mount
  useEffect(() => {
    dispatch(fetchListsDetailsStart());
  }, []);

  // Sorting logic based on props.sortingToogle
  useEffect(() => {
    if (props.userData && props.userData.length > 0) {
      const sortedData = [...props.userData];
      if (props.sortingToogle.aToz) {
        // Sort A to Z by username
        sortedData.sort((a, b) => a.otherUser?.username.localeCompare(b.otherUser?.username));
      } else if (!props.sortingToogle.aToz) {
        // Sort Z to A by username
        sortedData.sort((a, b) => b.otherUser?.username.localeCompare(a.otherUser?.username));
      }
      if (props.sortingToogle.upToDown) {
        // Sort latest to oldest by updated_at
        sortedData.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      }
      setSortedUserData(sortedData);
    } else {
      setSortedUserData([]);
    }
  }, [props.userData, props.sortingToogle]);

  return (
    <>
      <div
        role="tabpanel"
        className={
          props.activeSec === "active-sec"
            ? "tab-pane fade in active"
            : "tab-pane fade"
        }
        id="active"
      >
        {props.following.loading ? (
          <FollowingLoader />
        ) : (
          <>
            <Row>
              {sortedUserData && sortedUserData.length > 0 ? (
                sortedUserData.map((follower) =>
                  follower.otherUser || follower.user ? (
                    <UserCard
                      key={follower.id}
                      pagePath={props.pagePath}
                      friendData={props.following.data.friends}
                      friendListID={follower?.id}
                      page={props.page}
                      setUserData={props?.setUserData}
                      setCheckedUserId={props?.setCheckedUserId}
                      checkedUserId={props?.checkedUserId}
                      user={follower.otherUser || follower.user}
                      isSelectedData={props?.userData}
                      isRestricted={follower?.is_resticted}
                      friendExtra={
                        props.componentType === "friends" ? follower : null
                      }
                      friendType={props.componentType === "friends" ? true : false}
                      followingType={props.pagePath === "following" ? true : false}
                      isSelected={props?.isSelected}
                    />
                  ) : (
                    ""
                  )
                )
              ) : (
                <NoDataFound />
              )}
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default translate(FollowingActiveSec);
